<template>
  <div>
    <h1 class="page-header">BidBond Payments</h1>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-5">
            <div
                class="form-group"
            >
              <label>Start Date</label>
              <datepicker
                  v-model="start"
                  format="yyyy-MM-dd"
                  input-class="form-control bg-white"
                  required
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>End Date</label>
              <datepicker
                  v-model="end"
                  format="yyyy-MM-dd"
                  input-class="form-control bg-white"
                  required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <vue-good-table
                :columns="columns"
                :lineNumbers="true"
                :pagination-options="{ enabled: true,  position: 'bottom' }"
                :rows="payments"
                :search-options="{ enabled: true}"
            >
              <div slot="table-actions">
                <download-excel
                    v-if="reports.length"
                    :data="reports"
                    :title="'Payments'"
                    class="btn btn-primary pull-right export-btn"
                    name="payments.xls"
                >
                  <i class="fa fa-file-excel"></i> Export Excel
                </download-excel>
              </div>
            </vue-good-table>
          </div>
<!--          <div v-if="reports.length > 0" class="col-md-4">-->
<!--            <panel bodyClass="p-0" title="Pie Chart">-->
<!--              <apexchart :options="pieChart.options" :series="pieChart.series" type="pie"></apexchart>-->
<!--            </panel>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  mixins: [helper],
  data() {
    return {
      columns: [
        {
          label: 'Amount',
          field: 'amount',
          formatFn: this.formatFn
        },
        {
          label: 'Trx No',
          field: 'transaction_number',
        },
        {
          label: 'Reference',
          field: 'reference',
        },
        {
          label: 'Deal Date',
          field: 'deal_date',
        },
        {
          label: 'Trx Date',
          field: 'transaction_date',
        },
        {
          label: 'Account',
          field: 'account',
        },
      ],
      start: moment().startOf('Year').toDate(),
      end: moment().toDate(),
      // pieChart: {
      //   options: {
      //     chart: {
      //       height: 365,
      //       type: 'pie',
      //     },
      //     dataLabels: {
      //       dropShadow: {
      //         enabled: false,
      //         top: 1,
      //         left: 1,
      //         blur: 1,
      //         opacity: 0.45
      //       }
      //     },
      //     colors: ['#fb5597', '#f59c1a', '#348fe2', '#00acac', '#8753de', '#32a852', '#a84e32', '#a89632', '#3e32a8', '#a032a8', '#3294a8', '#f2edf2'],
      //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      //     title: {
      //       text: 'A pie chart showing varied bidbond Payments figures in a year'
      //     }
      //   },
      //   series: [44, 55, 13, 43, 22, 30, 50, 100, 34, 60, 32, 10]
      // },
    };
  },
  mounted() {
    this.fetch();
  },
  watch: {
    // chart_data() {
    //   this.pieChart.series = this.chart_data[0];
    //   this.pieChart.options.labels = this.chart_data[2];
    // },
    period() {
      if (this.start && this.end) {
        this.fetch();
      }
    },
  },
  computed: {
    ...mapGetters({
      payments: 'getPayments',
      reports: 'getPaymentsFormatted',
      // chart_data: 'getPaymentsChartData'
    }),
    period() {
      return [this.start, this.end].join();
    }
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchPayments', {from: this.start, to: this.end});
    },
  }
}
</script>